import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'

const Appointment = Loadable(lazy(() => import('./Appointment')))
const ArchivedList = Loadable(lazy(() => import('./ArchivedList')))
const AppointmentReminder = Loadable(
    lazy(() => import('./AppointmentReminder'))
)
const AppointmentStatus = Loadable(lazy(() => import('./AppointmentStatus')))

const appointmentRoutes = [
    {
        path: '/appointment',
        element: <Appointment />,
    },
    {
        path: '/appointment/archived_list',
        element: <ArchivedList />,
    },
    {
        path: '/appointment/reminder',
        element: <AppointmentReminder />,
    },
    {
        path: '/appointment/status',
        element: <AppointmentStatus />,
    },
]

export default appointmentRoutes
