import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    labels: [],
    loading: false,
    error: null,
}

const labelsSlice = createSlice({
    name: 'labels',
    initialState,
    reducers: {
        setLabels(state, action) {
            const newLabels = action.payload.filter(
                (newLabel) =>
                    !state.labels.some(
                        (existingLabel) => existingLabel.id === newLabel.id
                    )
            )

            state.labels = [...state.labels, ...newLabels]
        },
        getLabels(state, action) {
            state.labels = action.payload
        },
    },
})

export const { setLabels, getLabels } = labelsSlice.actions

export const selectLabels = (state) => state.labels.labels

export default labelsSlice.reducer
