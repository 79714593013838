import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import { INITIAL_ORDER } from 'app/utils/constant'
import { checkOutstandingInvSelectedItem } from 'app/utils/validator'
import { toRound, orderItemCalculator, serviceChargeCalculator, taxCalculator } from 'app/utils/calculation'

const initialState = {
    orderList: INITIAL_ORDER,
    staffList: [],
}

const BillingAndInvoiceSlice = createSlice({
    name: 'billing_invoice',
    initialState,
    reducers: {
        addOrderList: (state, { payload }) => {
            let get_payload = _.cloneDeep(payload)
            if (get_payload.created) {

                let item_point_applied = _.map(
                    get_payload.item_list,
                    'point_applied'
                )

                let item_total_point_applied = _.reduce(
                    item_point_applied,
                    (accumulator, currentNumber) =>
                        (Number(accumulator) || 0) + (Number(currentNumber) || 0),
                    0
                )

                let apply_point_amount = 0
                apply_point_amount = Number(item_total_point_applied) + (Number(get_payload.point_applied) || 0)

                let point = (
                    (Number(get_payload.point) || 0) +
                    (Number(apply_point_amount) || 0) +
                    (Number(get_payload.member_profile.point) || 0)
                ).toFixed(2)

                if (Number(point)) {
                    get_payload.member_profile.point = point
                }
            };
            state.orderList = get_payload
        },
        addStaffList: (state, { payload }) => {
            state.staffList = _.cloneDeep(payload)
        },
        updateItemList: (state, { payload }) => {
            if (['quantity', 'unit_price', 'remark'].includes(payload.field)) {
                state.orderList.item_list[payload.item_index][payload.field] =
                    payload.value

            } else {
                state.orderList.item_list[payload.item_index].payment[payload.field] =
                    payload.value

            }

            let count_title = [
                'quantity',
                'unit_price',
                'discount',
                'credit_applied',
                'point_applied',
                'payment_amount'
            ]

            if (count_title.includes(payload.field)) {
                let tempItem = _.cloneDeep(state.orderList.item_list[payload.item_index])

                let { quantity, unit_price, } = state.orderList.item_list[payload.item_index]

                let { discount, credit_applied, point_applied } = state.orderList.item_list[payload.item_index].payment

                let total = (Number(quantity) || 0) * (Number(unit_price) || 0)

                if (!'outstanding_inv' in tempItem) { // This is for general items 
                    state.orderList.item_list[payload.item_index].payment.payment_amount = total
                } else {// This is for OUTSTANDING INV Only
                    total = state.orderList.item_list[payload.item_index].payment.payment_amount
                }

                if (discount?.type === 1) {
                    total -= total * (Number(discount.amount) / 100)
                } else if (discount?.type === 2) {
                    total = total - (Number(discount.amount) || 0)
                }

                total = total - (Number(credit_applied) || 0) - (Number(point_applied) || 0)
                state.orderList.item_list[payload.item_index].total = total

                if (count_title.includes(payload.field)) {
                    state.orderList.credit_applied = 0
                    state.orderList.point_applied = 0
                    state.orderList.discount_amount = 0
                    state.orderList.discount = {}
                }

                if (payload.field == 'discount' && discount?.amount) {
                    state.orderList.item_list[payload.item_index].payment.credit_applied = ''
                    state.orderList.item_list[payload.item_index].payment.credit_type = ''
                    state.orderList.item_list[payload.item_index].payment.point_applied = ''
                } else if (['payment_amount', 'quantity'].includes(payload.field)) {
                    state.orderList.item_list[payload.item_index].payment.credit_applied = ''
                    state.orderList.item_list[payload.item_index].payment.credit_type = ''
                    state.orderList.item_list[payload.item_index].payment.point_applied = ''
                    state.orderList.item_list[payload.item_index].payment.discount = {}
                }
            }
        },
        updateOutstandingItemList: (state, { payload }) => {
            let { field, value, selectedIndex, category, category_id } = payload

            let selectedItem = {}
            let selectedItemIndex = ''
            let temp_list = _.cloneDeep(state.orderList.item_list[selectedIndex].order[category])

            _.map(temp_list, (item, index) => {
                if (category == 'package') {
                    if (item[category]['id'] == category_id['id']) {
                        selectedItem = { ...item }
                        selectedItemIndex = index
                    }
                } else {
                    if (item[category] == category_id) {
                        selectedItem = { ...item }
                        selectedItemIndex = index
                    }
                }
            })

            let count_title = [
                'payment_amount',
                'discount',
                'credit_applied',
                'point_applied',
                'credit_type'
            ]

            let selectedOrder = _.cloneDeep(state.orderList.item_list[selectedIndex].order)
            if (count_title.includes(field)) {
                selectedItem['payment'][field] = value
                let total = (Number(selectedItem?.payment?.payment_amount))
                let discount = _.cloneDeep(selectedItem?.payment?.discount)
                if (discount?.type === 1) {
                    total -= total * (Number(discount.amount) / 100)
                } else if (discount?.type === 2) {
                    total = total - (Number(discount.amount) || 0)
                }
                total = total - (Number(selectedItem?.payment?.credit_applied) || 0) - (Number(selectedItem?.payment?.point_applied) || 0)
                selectedItem.total = total

                selectedOrder[category][selectedItemIndex] = { ...selectedItem }

                if (['discount', 'credit_applied', 'point_applied'].includes(field)) {
                    state.orderList.credit_applied = 0
                    state.orderList.point_applied = 0
                    state.orderList.discount_amount = 0
                    state.orderList.discount = {}
                }

                if (field == 'discount' && discount?.amount) {
                    selectedItem.payment.credit_applied = ''
                    selectedItem.payment.credit_type = ''
                    selectedItem.payment.point_applied = ''
                    selectedOrder[category][selectedItemIndex] = { ...selectedItem }
                } else if (field == 'payment_amount') {
                    selectedItem.payment.credit_applied = ''
                    selectedItem.payment.credit_type = ''
                    selectedItem.payment.point_applied = ''
                    selectedItem.payment.discount = {}
                    selectedOrder[category][selectedItemIndex] = { ...selectedItem }
                }

                // Update the selected order object
                state.orderList.item_list[selectedIndex].order = { ...selectedOrder }
            } else {
                selectedItem[field] = value
                selectedOrder[category][selectedItemIndex] = { ...selectedItem }
                state.orderList.item_list[selectedIndex].order = { ...selectedOrder }
            }
        },
        updateStaffItem: (state, { payload }) => {
            let { type, item_index, value, staff_index } = payload
            state.orderList.item_list[item_index].staff[staff_index] = value

            if (state.orderList.item_list[item_index].staff.length <= 3) {
                if (staff_index == 0) {
                    if (!state.orderList.item_list[item_index].staff[1]) {
                        state.orderList.item_list[item_index].staff[1] = {}
                    }
                    if (!state.orderList.item_list[item_index].staff[2]) {
                        state.orderList.item_list[item_index].staff[2] = {}
                    }
                } else {
                    if (!state.orderList.item_list[item_index].staff[0]) {
                        state.orderList.item_list[item_index].staff[0] = {}
                    }
                }
            }
        },
        updateOutstandingItemStaff: (state, { payload }) => {
            let { outstanding_inv_id, value, category, category_id, staff_index } = payload

            let selectedIndex = ''
            let updated_list = []
            _.map(state.orderList.item_list, (item, index) => {
                if (_.has(item, 'outstanding_inv')) {
                    if (item.outstanding_inv == outstanding_inv_id) {
                        selectedIndex = index
                        // Loop in the outstanding order selected category object array
                        _.map(item.order[category], orderItem => {
                            //search for the selected item 
                            if (orderItem[category] == category_id) {
                                // update the selected staff value 
                                let temp = { ...orderItem }
                                temp.staff[staff_index] = value
                                updated_list.push(temp)
                            } else {
                                updated_list.push(orderItem)
                            }
                        })
                    }
                }
            })

            state.orderList.item_list[selectedIndex].staff = [...updated_list]
        },
        removeOrderItem: (state, { payload }) => {
            let { index_item } = payload
            _.remove(
                state.orderList.item_list,
                (item, index) => index == index_item
            )
        },
        removeOutstandingItem: (state, { payload }) => {
            let { outstanding_inv_id, category, category_id } = payload
            let selectedIndex = ''
            let serviceChecker = ''
            let packageChecker = ''
            let productChecker = ''
            let creditChecker = ''

            _.map(state.orderList.item_list, (el, index) => {
                if (_.has(el, 'outstanding_inv')) {
                    if (el.outstanding_inv == outstanding_inv_id) {
                        selectedIndex = index
                        _.map(el.order[category], item => {
                            if (category == 'package') {
                                if (item[category]['id'] == category_id['id']) {
                                    item.is_selected = false
                                }
                            } else {
                                if (item[category] == category_id) {
                                    item.is_selected = false
                                }
                            }
                        })
                        // Check if all the items from the outstanding order has been deselected 
                        serviceChecker = checkOutstandingInvSelectedItem(el?.order?.service)
                        packageChecker = checkOutstandingInvSelectedItem(el?.order?.package)
                        productChecker = checkOutstandingInvSelectedItem(el?.order?.product)
                        creditChecker = checkOutstandingInvSelectedItem(el?.order?.credit)


                    }
                }
            })

            if (!serviceChecker && !packageChecker && !productChecker && !creditChecker) {
                // Items might have same ID, use index the optimal way to do it
                _.remove(state.orderList.item_list, (item, index) => index == selectedIndex)
            }

        },
        updateBillingPayment: (state, { payload }) => {
            state.orderList.payment = [...state.orderList.payment, payload]
        },
        removeBillingPayment: (state, { payload }) => {
            _.remove(state.orderList.payment, (item, index) => index == payload)
        },
        updateBillingMethods: (state, { payload }) => {
            let { payment, payment_index } = payload
            state.orderList.payment[payment_index] = payment
        },
        updateOrderParentKey: (state, { payload }) => {
            let { field, value } = payload

            state.orderList[field] = value

            if (['credit_applied', 'point_applied'].includes(field)) {
                state.orderList.discount = {}
                state.orderList.discount_amount = 0
            }

            if (field == 'discount') {
                let discountAmount = 0
                let subtotal = state.orderList.subtotal
                if (Number(value.amount)) {
                    if (value.type == 1 && Number(subtotal) > 0) {
                        discountAmount =
                            (subtotal -
                                Number(state.orderList.credit_applied) -
                                Number(state.orderList.point_applied)) *
                            (Number(value.amount) / 100)
                    } else if (value.type == 2) {
                        discountAmount = Number(value.amount)
                    }
                }

                state.orderList.discount_amount = discountAmount
            }

            let billing_profile = ['member_profile', 'customer_profile']

            if (billing_profile.includes(field)) {
                let clone_item_list = _.cloneDeep(state.orderList.item_list)
                let get_new_item_list = _.map(clone_item_list, (item) => {
                    let item_obj_total =
                        (Number(item.quantity) || 0) * (Number(item.unit_price) || 0)

                    if (item?.payment?.discount?.type === 1) {
                        item_obj_total =
                            item_obj_total -
                            item_obj_total *
                            (Number(item?.payment?.discount.amount) / 100)
                    } else if (item?.payment?.discount?.type === 2) {
                        item_obj_total =
                            item_obj_total - (Number(item?.payment?.discount.amount) || 0)
                    }

                    return {
                        ...item,
                        credit_applied: '',
                        point_applied: '',
                        total: item_obj_total,
                    }
                })

                state.orderList.item_list = get_new_item_list
                state.orderList.credit_applied = 0
                state.orderList.point_applied = 0
            }
        },
        updateBillingBalance: (state, { payload }) => {
            let {
                serviceTotal,
                productTotal,
                packageTotal,
                creditTotal,
                outstandingInvTotal,
            } = orderItemCalculator(state.orderList.item_list)

            let item_payment_ammount = _.map(state.orderList.payment, 'amount')

            let subTotal =
                serviceTotal +
                productTotal +
                packageTotal +
                creditTotal +
                outstandingInvTotal

            let payment = _.reduce(
                item_payment_ammount,
                (accumulator, currentNumber) =>
                    (Number(accumulator) || 0) + (Number(currentNumber) || 0),
                0
            )

            let service_charge = serviceChargeCalculator(subTotal - outstandingInvTotal) // exclude the outstanding invoice payment
            let tax = taxCalculator(subTotal - outstandingInvTotal, service_charge) // exclude the outstanding invoice payment

            let total =
                subTotal -
                (Number(state.orderList.credit_applied) + Number(state.orderList.point_applied) +
                    Number(state.orderList.discount_amount)) + Number(tax)

            let rounded_total = toRound(total)
            state.orderList.rounding = Number(Number(rounded_total) - total).toFixed(2)
            state.orderList.subtotal = subTotal
            state.orderList.total = Number(rounded_total)
            state.orderList.payment_amount = payment
            state.orderList.service_charge = Number(service_charge)
            state.orderList.tax = Number(tax)
            state.orderList.balance = Number(rounded_total) - payment

            let item_total_point_applied = 0
            let total_general_credit_applied = 0
            let total_service_credit_applied = 0
            let total_product_credit_applied = 0

            state.orderList.item_list.map(el => {
                if (el?.payment?.credit_type == 'Service') {
                    total_service_credit_applied += Number(el?.payment?.credit_applied) || 0
                } else if (el?.payment?.credit_type == 'Product') {
                    total_product_credit_applied += Number(el?.payment?.credit_applied) || 0
                } else if (el?.payment?.credit_type == 'All') {
                    total_general_credit_applied += Number(el?.payment?.credit_applied) || 0
                }

                item_total_point_applied += Number(el?.payment?.point_applied) || 0
            })


            let apply_credit_amount =
                total_general_credit_applied +
                (Number(state.orderList.credit_applied) || 0)

            let apply_point_amount =
                item_total_point_applied +
                (Number(state.orderList.point_applied) || 0)
            state.orderList.used_general_credit = apply_credit_amount
            state.orderList.used_service_credit = total_service_credit_applied
            state.orderList.used_product_credit = total_product_credit_applied
            state.orderList.used_point = apply_point_amount


            if (state.orderList.created) {
                state.orderList.credit = (state.orderList.credit || 0) + apply_credit_amount
                state.orderList.point = (state.orderList.point || 0) + apply_point_amount
            };
        },
    },
})

export const {
    addStaffList,
    addOrderList,
    updateItemList,
    updateStaffItem,
    removeOrderItem,
    updateBillingPayment,
    removeBillingPayment,
    updateBillingMethods,
    updateOrderParentKey,
    updateBillingBalance,
    removeOutstandingItem,
    updateOutstandingItemList,
    updateOutstandingItemStaff,
} = BillingAndInvoiceSlice.actions

export default BillingAndInvoiceSlice.reducer
