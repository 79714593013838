import React from 'react'
import AuthGuard from 'app/auth/AuthGuard'
import wabaRoutes from 'app/views/waba/WabaRoutes'
import NotFound from 'app/views/sessions/NotFound'
import staffRoutes from 'app/views/staff/StaffRoutes'
import ActivityTracker from 'app/utils/ActivityTracker'
import creditRoutes from '../views/credit/CreditRoutes'
import Loadable from 'app/components/Loadable/Loadable'
import reportRoutes from 'app/views/report/ReportRoutes'
import memberRoutes from 'app/views/member/MemberRoutes'
import WebStoreCart from '../views/webstore/WebStoreCart'
import invoiceRoutes from 'app/views/invoice/InvoiceRoutes'
import packageRoutes from 'app/views/package/PackageRoutes'
import expenseRoutes from 'app/views/expense/ExpenseRoutes'
import billingRoutes from 'app/views/billing/BillingRoutes'
import serviceRoutes from 'app/views/service/ServiceRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import masterRoutes from 'app/views/masterData/MasterRoutes'
import merchantRoutes from 'app/views/merchant/MerchantRoutes'
import settingRoutes from '../views/shopSetting/SettingRoutes'
import webStoreRoutes from 'app/views/webstore/WebStoreRoutes'
import customerRoutes from 'app/views/customer/CustomerRoutes'
import MatxLayout from 'app/components/Theme/Layout/MatxLayout'
import inventoryRoutes from '../views/inventory/InventoryRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import quotationRoutes from 'app/views/quotation/QuotationRoutes'
import commissionRoutes from 'app/views/commission/CommissionRoutes'
import appointmentRoutes from 'app/views/appointment/AppointmentRoutes'
import termsPolicyRoutes from 'app/views/terms&policy/TermsPolicyRoutes'
import WebStoreDetailRouter from '../views/webstore/WebstoreDetail/WebStoreDetailRouter'
import FmcNotification from 'app/components/FcmNotification'

const DefaultNavigate = Loadable(
    React.lazy(() => import('app/components/DefaultNavigate'))
)

const WebstoreLive = Loadable(
    React.lazy(() => import('../views/webstore/WebstoreLive'))
)

const CompanyInvoice = Loadable(
    React.lazy(() => import('app/views/invoice/CompanyInvoice'))
)

const ShopInvoice = Loadable(
    React.lazy(() => import('app/views/billing/ShopInvoice'))
)

const QuotationPDF = Loadable(
    React.lazy(() => import('app/views/quotation/QuotationPDF'))
)

export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard>
                    <FmcNotification />
                    <ActivityTracker />
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [
                ...wabaRoutes,
                ...staffRoutes,
                ...memberRoutes,
                ...reportRoutes,
                ...masterRoutes,
                ...creditRoutes,
                ...serviceRoutes,
                ...packageRoutes,
                ...expenseRoutes,
                ...invoiceRoutes,
                ...settingRoutes,
                ...billingRoutes,
                ...webStoreRoutes,
                ...customerRoutes,
                ...merchantRoutes,
                ...inventoryRoutes,
                ...quotationRoutes,
                ...dashboardRoutes,
                ...commissionRoutes,
                ...appointmentRoutes,
            ],
        },
        ...sessionRoutes,
        ...termsPolicyRoutes,
        {
            path: '/',
            element: <DefaultNavigate />,
        },
        {
            path: '/webstore/shop/:shop_code',
            element: <WebstoreLive />,
        },
        {
            path: '/webstore/shop/:shop_code/cart',
            element: <WebStoreCart />,
        },
        {
            path: '/webstore/shop/:shop_code',
            children: [...WebStoreDetailRouter],
        },
        {
            path: '/shop_invoice/:doc_no/:shop_code',
            element: <ShopInvoice />,
        },
        {
            path: '/shop_invoice/:doc_no/:shop_code/:type',
            element: <ShopInvoice />,
        },
        {
            path: '/company_invoice/:doc_no/:shop_code',
            element: <CompanyInvoice />,
        },
        {
            path: '/company_invoice/:doc_no/:shop_code/:type',
            element: <CompanyInvoice />,
        },
        {
            path: '/quotation_doc/:doc_no/:shop_code',
            element: <QuotationPDF />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
