import React from 'react'
import { Span } from '../Typography'
import { styled, Box } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import useSettings from 'app/hooks/useSettings'

const BrandRoot = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 18px 20px 29px',
}))

const StyledSpan = styled(Span)(({ theme, mode }) => ({
    fontSize: 24,
    fontFamily: 'Copperplate',
    letterSpacing: -1,
    marginLeft: '.5rem',
    color: '#FFFFFF',
    color: '#00b9c6',
    display: mode === 'compact' ? 'none' : 'block',
}))

const IMG = styled('img')({
    width: '35px',
})

const Brand = ({ children }) => {
    const { settings, updateSettings } = useSettings()
    const leftSidebar = settings.layout1Settings.leftSidebar
    const { mode } = leftSidebar
    const navigate = useNavigate()

    const updateSidebarMode = (sidebarSettings) => {
        let activeLayoutSettingsName = settings.activeLayout + 'Settings'
        let activeLayoutSettings = settings[activeLayoutSettingsName]

        updateSettings({
            ...settings,
            [activeLayoutSettingsName]: {
                ...activeLayoutSettings,
                leftSidebar: {
                    ...activeLayoutSettings.leftSidebar,
                    ...sidebarSettings,
                },
            },
        })
    }

    return (
        <BrandRoot
            sx={{
                backgroundColor: 'white',
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                onClick={() => {
                    // link it to Default Navigator
                    navigate('/')
                    updateSidebarMode({ mode: 'close' })
                }}
                sx={{ '&:hover': { cursor: 'pointer' } }}
            >
                <IMG src="/assets/images/logo-320.png" alt="" />
                <StyledSpan mode={mode} className="sidenavHoverShow dashboard-logo">
                    LaBéau
                </StyledSpan>
            </Box>
            <Box
                className="sidenavHoverShow"
                sx={{ display: mode === 'compact' ? 'none' : 'block' }}
            >
                {children || null}
            </Box>
        </BrandRoot>
    )
}

export default Brand
