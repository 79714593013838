const initialState = {
    memberList: [],
    memberLabels: [],
}
const MemberReducer = function (state = initialState, action) {
    switch (action.type) {
        case 'FETCH_MEMBER_LIST': {
            return {
                ...state,
                memberList: action.payload,
            }
        }
        case 'FETCH_MEMBER_TAGS': {
            return {
                ...state,
                memberLabels: [...action.payload],
            }
        }
        case 'ADD_NEW_TAG': {
            return {
                ...state,
                memberLabels: [...state.memberLabels, action.payload],
            }
        }
        case 'REMOVE_TAG': {
            return {
                ...state,
                memberLabels: state.memberLabels.filter(
                    (tag) => tag.id !== action.payload
                ),
            }
        }
        case 'REMOVE_TAG_FROM_MEMBERS': {
            return {
                ...state,
            }
        }
        default: {
            return state
        }
    }
}

export default MemberReducer
